<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pb-0 body-2 d-flex justify-center">
        <h4 class="text-center">{{$_strings.order.DAMAGE_REPORT}}</h4>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" class="custom__card" lazy-validation>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>{{$_strings.order.NO_CARGO}}</span>
            </v-col>
            <v-col class="pt-0 pt-sm-3" cols="12" sm="4">
              <span>{{item && item.cargoTitle}}</span>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col class="pb-0 pt-0" cols="12" sm="4">
              <span>{{$_strings.order.ORDER_NUMBER}}</span>
            </v-col>
            <v-col class="pt-0" cols="12" sm="4">
              <span>{{item && item.shipmentsTitle}}</span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="pb-0 pt-0" cols="12">
              <h4>
                {{$_strings.order.REPORT_DATE}}
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-row>
                <v-col class="pb-0" cols="6">
                  <v-menu
                    ref="menuBaspDate"
                    v-model="menuBaspDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    class="caption"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        class="caption"
                        placeholder="Tanggal"
                        prepend-inner-icon="mdi-calendar"
                        :value="form.baspDate && dateFormat(form.baspDate)"
                        :rules="rules.date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.baspDate"
                      :min="minDate()"
                      :max="maxDate()"
                      @input="menuBaspDate = false"
                      @change="handleDate"
                      no-title
                      scrollable
                      locale="id"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="6">
                  <v-text-field
                    outlined
                    dense
                    ref="time"
                    placeholder="Jam"
                    v-mask="{'alias': 'time', rightAlign: false}"
                    @input="setPickupDate"
                    v-model="time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    class="caption"
                    :rules="[($event) => rules.time($event)]"
                    :value="time && timeFormat(time)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12">
              <h4>
                {{$_strings.order.BASP_REASON}}
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-autocomplete
                :loading="isLoadingListBaspReason"
                v-model="tempBaspTitle"
                :items="itemsBasp"
                class="caption"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.BASP_REASON)]"
                outlined
                dense
                item-text="reason"
                item-value="id"
                @change="handleBaspTitle"
                @input.native="searchBaspReason"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.EXPLANATION_OF_REASON}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                outlined
                class="caption"
                dense
                v-model="form.baspNotes"
                :counter="255"
                rows="5"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.EXPLANATION_OF_REASON)]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.TYPE_OF_GOODS}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                readonly
                v-model="form.baspCategory"
                outlined
                class="caption"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.DESCRIPTION}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                v-model="form.note"
                outlined
                class="caption"
                dense
                :counter="255"
                rows="5"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.DESCRIPTION)]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col class="pb-0 pt-0" cols="12">
                  <h4>
                    {{$_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE}}
                    <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                  </h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <div class="img-box">
                    <div v-if="form.baspPhoto[0]" class="box-mdi-close">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="removeImage('baspPhotoModel', 'baspPhoto')"
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <span>Hapus Foto</span>
                      </v-tooltip>
                    </div>
                    <img v-if="form.baspPhoto[0]" :src="form.baspPhoto[0]" alt="">
                  </div>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-file-input
                    :clearable="false"
                    show-size
                    ref="basp_photo"
                    @change="($event) => setFileInput($event, 'baspPhoto', 'baspPhotoModel')"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE)]"
                    v-model="baspPhotoModel"
                    :disabled="isLoading"
                    label="Pilih Foto"
                    type="file"
                    accept="image/*"
                    outlined
                    dense
                    prepend-icon=""
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        style="w-50"
                        small
                        label
                        color="primary"
                      >
                        {{ text.length > 20 ? text.substr(0, 20) : text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col class="pb-0 pt-0" cols="12">
                  <h4>{{$_strings.order.DOCUMENTATION}}</h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <div class="img-box">
                    <div v-if="form.documentationPhoto[0]" class="box-mdi-close">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="removeImage('documentationPhotoModel', 'documentationPhoto')"
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <span>Hapus Foto</span>
                      </v-tooltip>
                    </div>
                    <img v-if="form.documentationPhoto[0]" :src="form.documentationPhoto[0]" alt="">
                  </div>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-file-input
                    :clearable="false"
                    show-size
                    ref="documentation_photo"
                    @change="($event) => setFileInput($event, 'documentationPhoto', 'documentationPhotoModel')"
                    :disabled="isLoading"
                    label="Pilih Foto"
                    v-model="documentationPhotoModel"
                    type="file"
                    accept="image/*"
                    outlined
                    dense
                    prepend-icon=""
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        style="w-50"
                        small
                        label
                        color="primary"
                      >
                        {{ text.length > 20 ? text.substr(0, 20) : text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          @click="submit"
          :loading="isLoading"
        >
          {{$_strings.order.SEND_BASP}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {
  rulesNotes,
  getDateTimeTz,
  dateFormat,
  timeFormat,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();
let searchBaspReason;

export default {
  data() {
    return {
      isLoading: false,
      isLoadingListBaspReason: false,
      dialog: false,
      title: '',
      shipmentCargoId: '',
      item: {}, // from page order detail
      tempBaspTitle: '',
      itemsBasp: [],
      documentationPhotoModel: null,
      baspPhotoModel: null,
      menuBaspDate: false,
      time: null,
      rules: {
        date: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Tanggal'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
      form: {
        baspDate: null,
        shipmentsId: null,
        locationId: null,
        baspTitle: '',
        isNeedConfirm: false,
        baspCategory: '',
        baspNotes: '',
        receiverName: '',
        baspPhoto: [],
        documentationPhoto: [],
        note: '',
        datetimeTz: getDateTimeTz(),
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.form = {
          baspDate: null,
          shipmentsId: null,
          locationId: null,
          baspTitle: '',
          isNeedConfirm: false,
          baspCategory: '',
          baspNotes: '',
          receiverName: '',
          baspPhoto: [],
          documentationPhoto: [],
          note: '',
          datetimeTz: getDateTimeTz(),
        };
        this.tempBaspTitle = '';
        this.baspPhotoModel = null;
        this.documentationPhotoModel = null;
        this.$refs.form.reset();
        return;
      }
      if (!this.itemsBasp.length) {
        this.getListBaspReason();
      }
      const { shipmentsId, locationId } = this.item;
      this.form = {
        ...this.form,
        shipmentsId,
        locationId,
      };
      this.form.baspCategory = `${this.item.categoryName} - ${this.item.subCategoryName}`;
      source = CancelToken.source();
    },
  },
  methods: {
    rulesNotes,
    dateFormat,
    timeFormat,
    dayjs,
    minDate() {
      return dayjs(this.item.pickupDate).format();
    },
    maxDate() {
      return dayjs().add(1, 'months').format();
    },
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.baspDate && timePattern.test(time)) {
        const date = dayjs(this.form.baspDate).format('YYYY-MM-DD');
        const baspDate = dayjs(`${date} ${time}`).format();
        this.form.baspDate = baspDate;
      }
    },
    handleDate() {
      this.setPickupDate(this.time);
      this.$refs.time.focus();
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'svg'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    searchBaspReason(event) {
      clearTimeout(searchBaspReason);
      searchBaspReason = setTimeout(() => this.getListBaspReason(event), 200);
    },
    async getListBaspReason(event) {
      const filters = {
        page: 0,
        size: 50,
        type: 'qIn(basp)',
        sort: 'name,desc',
        isActive: 'qistrue',
        needConfirm: 'qisfalse',
      };
      if (event) {
        filters.name = `qLike(${event.target.value})`;
      }
      this.isLoadingListBaspReason = true;
      try {
        const res = await this.$_services.masterData.fetchSelectionsInfos(filters);
        this.itemsBasp = res.data.contents;
      } finally {
        this.isLoadingListBaspReason = false;
      }
    },
    handleBaspTitle(idBaspReason) {
      const findBasp = this.itemsBasp.find((item) => item.id === idBaspReason);
      this.form.baspTitle = findBasp.reason;
      this.form.isNeedConfirm = findBasp.needConfirm;
    },
    setFileInput(file, type, fileModel) {
      const valid = this.fileUploadValidation(file);
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = (e) => {
        if (!valid) {
          this[fileModel] = null;
          return;
        }
        this.form[type] = [e.target.result];
      };
    },
    removeImage(fileInput, type) {
      this[fileInput] = null;
      this.form[type] = [];
    },
    async submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const { shipmentsCargoId } = this.item;
        try {
          this.isLoading = true;
          await this.$_services.order.basp(shipmentsCargoId, this.form, source);
          this.$dialog.notify.success('Berhasil');
          this.$emit('fetchData');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom__card {
  border: 1px solid rgb(110, 110, 110);
  border-radius: 5px;
  padding: 10px 30px;
}
.img-box{
  margin-bottom: 2px;
  display: block;
  width: 100%;
  border: 1px solid rgb(189, 183, 183);
  height: 120px;
  position: relative;
  img {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
  .box-mdi-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }
}
</style>
