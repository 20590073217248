<template>
  <section>
    <v-row v-if="showGroupBtnUpdate" justify="end">
      <v-col v-if="showBtnBasp" cols="auto" class="pr-0">
        <v-btn
          block
          color="red"
          class="white--text"
          @click="showDialogFormBasp"
        >
          <span class="text-none">
            {{$_strings.order.REPORT_DIFFERENCE}}
          </span>
        </v-btn>
      </v-col>
      <v-col v-if="showBtnBast" cols="auto">
        <v-btn
          :loading="isLoadingShipmentDetails"
          block
          color="primary"
          class="no-text-transform body-2"
          @click="showDialogFormBast"
        >
          <span class="text-none">
            {{ $_strings.order.CREATE_BAST }}
          </span>
        </v-btn>
      </v-col>
      <v-col v-else-if="showBtnSaveAndContinue" cols="auto">
        <v-btn
          block
          color="primary"
          class="no-text-transform body-2"
          @click="handleSaveAndContinue"
          :loading="isLoadingSaveAndContinue || isLoadingShipmentDetails"
        >
          <span class="text-none">
            {{ $_strings.order.SAVE_AND_CONTINUE }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <DialogBast
      ref="dialogBast"
      @fetchData="$emit('fetchData')"
    />
    <DialogBasp
      ref="dialogBasp"
      @fetchData="$emit('fetchData')"
    />
  </section>
</template>

<script>
import { getDateTimeTz } from '@/helper/commonHelpers';
import DialogBast from '../Dialog/Bast.vue';
import DialogBasp from '../Dialog/Basp.vue';

export default {
  components: {
    DialogBast,
    DialogBasp,
  },
  props: {
    shipmentOrderNumber: {
      type: String,
      default: '',
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    itemSequence: {
      type: Object,
      default: () => {},
    },
    routeForUpdate: {
      type: Object,
      default: () => {},
    },
    isLoadingShipmentDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingSaveAndContinue: false,
    };
  },
  computed: {
    showGroupBtnUpdate() {
      if (this.routeForUpdate.shipments) {
        return this.routeForUpdate.shipments.map((r) => r.shipmentsId).includes(this.shipment.id) && (this.itemSequence.showBtnSaveAndContinue || this.itemSequence.showBtnBAST);
      }
      return false;
    },
    showBtnSaveAndContinue() {
      if (this.routeForUpdate && this.routeForUpdate.listShipments) {
        return !this.routeForUpdate.listShipments.includes(this.shipment.id);
      }
      return this.itemSequence.showBtnSaveAndContinue;
    },
    showBtnBast() {
      if (this.routeForUpdate && this.routeForUpdate.listShipmentBast) {
        return !this.routeForUpdate.listShipmentBast.includes(this.shipmentOrderNumber);
      }
      return this.itemSequence.showBtnBAST;
    },
    showBtnBasp() {
      const { orderStatus = '' } = this.shipment;
      if (this.showBtnBast && orderStatus.toLowerCase() !== 'barang diterima tidak utuh') return true;
      return this.showBtnBast;
    },
  },
  methods: {
    getDateTimeTz,
    showDialogFormBasp() {
      const item = {
        cargoTitle: this.routeForUpdate.cargoNumber,
        locationId: this.itemSequence.locationId,
        shipmentsId: this.shipment.id,
        shipmentsTitle: this.shipment.orderNumber,
        categoryName: this.shipment.category,
        subCategoryName: this.shipment.subCategory,
        additionalNote: this.shipment.goodsNotes,
        shipmentsCargoId: this.routeForUpdate.shipmentsCargoId,
        pickupDate: this.routeForUpdate.pickupDate,
      };
      this.$refs.dialogBasp.item = item;
      this.$refs.dialogBasp.dialog = true;
    },
    showDialogFormBast() {
      const item = {
        locationId: this.itemSequence.locationId,
        shipmentId: this.shipment.id,
        shipmentsTitle: this.shipment.orderNumber,
        categoryName: this.shipment.category,
        subCategoryName: this.shipment.subCategory,
        additionalNote: this.shipment.goodsNotes,
        shipmentsCargoId: this.routeForUpdate.shipmentsCargoId,
        pickupDate: this.routeForUpdate.pickupDate,
      };
      this.$refs.dialogBast.item = item;
      this.$refs.dialogBast.dialog = true;
    },
    async handleSaveAndContinue() {
      try {
        this.isLoadingSaveAndContinue = true;
        const { shipmentsCargoId } = this.routeForUpdate;
        const { locationId, notes, status } = this.itemSequence;
        const form = {
          locationId,
          notes,
          status,
          datetimeTz: this.getDateTimeTz(),
        };
        await this.$_services.order.updateStatusCargoAtLocation(shipmentsCargoId, form);
        this.$dialog.notify.success('Berhasil update status kargo');
        this.$emit('fetchData');
      } finally {
        this.isLoadingSaveAndContinue = false;
      }
    },
  },
};
</script>
