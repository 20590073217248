<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
      scrollable
    >
      <v-form ref="form">
        <v-card :loading="isLoading">
          <v-card-title class="caption">
            <v-row>
              <v-col class="d-flex pa-0 ma-0 justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title class="pb-0 body-2 d-flex justify-center">
            <h4 class="text-center">{{$_strings.order.GOODS_HANDOVER}}</h4>
          </v-card-title>
          <v-card-text class="mt-4">
            <section class="custom__card">
              <v-row>
                <v-col cols="12" sm="4">
                  <span>{{$_strings.order.ORDER_NUMBER}}</span>
                </v-col>
                <v-col cols="12" sm="8">
                  <span>{{item && item.shipmentsTitle}}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0" cols="12" sm="4">
                  <span>{{$_strings.order.GOODS_CATEGORY}}</span>
                </v-col>
                <v-col class="pt-0 pt-sm-3" cols="12" sm="8">
                  <span>{{item && item.categoryName}}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0" cols="12" sm="4">{{$_strings.order.GOODS_SUB_CATEGORY}}</v-col>
                <v-col class="pt-0 pt-sm-3" cols="12" sm="8">
                  <span>{{item && item.subCategoryName}}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0" cols="12">
                  <h4>{{$_strings.order.ADDITIONAL_INFORMATION}}</h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    :value="(item && item.additionalNote) || '-'"
                    filled
                    disabled
                    outlined
                    name="input-7-1"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="pb-0 pt-0" cols="12">
                  <h4>
                    {{$_strings.order.BAST_DATE}}
                    <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                  </h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-row>
                    <v-col class="pb-0" cols="6">
                      <v-menu
                        ref="menuCofirmDate"
                        v-model="menuCofirmDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        class="caption"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            class="caption"
                            placeholder="Tanggal"
                            prepend-inner-icon="mdi-calendar"
                            :value="form.confirmationDate && dateFormat(form.confirmationDate)"
                            :rules="rules.date"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.confirmationDate"
                          :min="minDate()"
                          :max="maxDate()"
                          @input="menuCofirmDate = false"
                          @change="handleDate"
                          no-title
                          scrollable
                          locale="id"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="pb-0" cols="6">
                      <v-text-field
                        outlined
                        dense
                        ref="time"
                        placeholder="Jam"
                        v-mask="{'alias': 'time', rightAlign: false}"
                        @input="setPickupDate"
                        v-model="time"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        class="caption"
                        :rules="[($event) => rules.time($event)]"
                        :value="time && timeFormat(time)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0 pt-0" cols="12">
                  <h4>{{$_strings.order.RECEPIENTS_NAME}}</h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    outlined
                    v-model="form.receiverName"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    ref="textarea"
                    outlined
                    v-model="form.notes"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.NOTE)]"
                    placeholder="Catatan"
                    name="input-7-1"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0 pt-0" cols="12">
                  <h4>
                    {{$_strings.order.SIGNATURE}}
                    <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                  </h4>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <div class="img-box">
                    <img v-if="form.encodedFile" :src="form.encodedFile" alt="">
                  </div>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="6">
                  <v-file-input
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.SIGNATURE)]"
                    show-size
                    :clearable="false"
                    ref="encodedFile"
                    @change="setImageSignature"
                    :disabled="isLoading"
                    v-model="fileInput"
                    label="Pilih Foto"
                    type="file"
                    accept="image/*"
                    outlined
                    dense
                    prepend-icon=""
                  />
                </v-col>
              </v-row>
            </section>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              @click="submit"
              :disabled="isLoading"
            >
              {{$_strings.order.DELIVERY_COMPLETE}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import {
  rulesNotes,
  getDateTimeTz,
  dateFormat,
  timeFormat,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      title: '',
      shipmentCargoId: '',
      item: {}, // from page order detail
      fileInput: null,
      menuCofirmDate: false,
      time: null,
      rules: {
        date: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Tanggal'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
      form: {
        confirmationDate: null,
        additionalInformation: '',
        receiverName: '',
        notes: '',
        encodedFile: '',
        locationId: null,
        shipmentId: null,
        datetimeTz: this.getDateTimeTz(),
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form.notes = '';
        source.cancel('CANCELED_BY_THE_USER');
        this.form = {
          confirmationDate: null,
          additionalInformation: '',
          receiverName: '',
          notes: '',
          encodedFile: '',
          locationId: null,
          shipmentId: null,
          datetimeTz: this.getDateTimeTz(),
        };
        // this.$refs.encodedFile.reset();
        this.fileInput = null;
        return;
      }
      const { shipmentId, locationId } = this.item;
      this.form = {
        ...this.form,
        shipmentId,
        locationId,
      };
      source = CancelToken.source();
    },
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    dateFormat,
    timeFormat,
    dayjs,
    minDate() {
      return dayjs(this.item.pickupDate).format();
    },
    maxDate() {
      return dayjs().add(1, 'months').format();
    },
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.confirmationDate && timePattern.test(time)) {
        const date = dayjs(this.form.confirmationDate).format('YYYY-MM-DD');
        const confirmationDate = dayjs(`${date} ${time}`).format();
        this.form.confirmationDate = confirmationDate;
      }
    },
    handleDate() {
      this.setPickupDate(this.time);
      this.$refs.time.focus();
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setImageSignature(file) {
      const valid = this.fileUploadValidation(file);
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = (e) => {
        if (!valid) {
          this.fileInput = null;
          return;
        }
        this.form.encodedFile = e.target.result;
      };
    },
    async submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const { shipmentsCargoId } = this.item;
        try {
          this.isLoading = true;
          await this.$_services.order.bast(shipmentsCargoId, this.form, source);
          this.$dialog.notify.success('Berhasil');
          this.$emit('fetchData');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .custom__card {
    border: 1px solid rgb(110, 110, 110);
    border-radius: 5px;
    padding: 10px 30px;
  }
  .img-box{
    margin-bottom: 2px;
    display: block;
    width: 50%;
    border: 1px solid rgb(189, 183, 183);
    height: 120px;
    img {
      object-fit: contain;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>
